import React from "react";
import { Link } from "react-router-dom";

const locations = [
  {
    id: 1,
    name: "Gamlebyen",
    city: "Fredrikstad",
    distance: "2.5km",
    type: "Familie, Turister",
    image: "/assets/gamlebyen.png",
    link: "/lokasjoner/gamlebyen",
  },
  {
    id: 2,
    name: "Domkirkeodden",
    city: "Hamar",
    distance: "2.1km",
    type: "Familie",
    image: "/assets/hamar.png",
    link: "/lokasjoner/hamar",
  },
];

const LocationCard = ({ location }) => (
  <Link
    to={location.link}
    className="min-w-[300px] sm:min-w-[350px] h-[400px] relative rounded-lg overflow-hidden mx-2 group"
  >
    <div
      className="w-full h-full bg-cover bg-center transition-transform duration-300 group-hover:scale-105"
      style={{ backgroundImage: `url(${location.image})` }}
    />
    <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent opacity-60" />
    <div className="absolute bottom-0 w-full p-4 flex justify-between items-end">
      <div className="text-white">
        <h3 className="text-xl font-bold">{location.name}</h3>
        <p className="text-sm">{location.city}</p>
      </div>
      <div className="text-white text-right">
        <p className="text-sm font-medium">{location.distance}</p>
        <p className="text-sm">{location.type}</p>
      </div>
    </div>
  </Link>
);

const Lokasjoner = () => {
  return (
    <section className="bg-[#ecf0ed] py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mb-12">
          <h2 className="text-4xl font-bold text-gray-900 mb-2">
            Se alle våre lokasjoner
          </h2>
          <div className="w-24 h-1 bg-black mb-4"></div>
        </div>

        {/* Slider Container */}
        <div className="relative">
          <div className="overflow-x-auto pb-4 hide-scrollbar">
            <div className="flex space-x-4 scroll-smooth">
              {locations.map((location) => (
                <LocationCard key={location.id} location={location} />
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Custom scrollbar styling */}
      <style jsx>{`
        .hide-scrollbar {
          scrollbar-width: none; /* Firefox */
          -ms-overflow-style: none; /* IE and Edge */
        }
        .hide-scrollbar::-webkit-scrollbar {
          display: none; /* Chrome, Safari, Opera */
        }
      `}</style>
    </section>
  );
};

export default Lokasjoner;
