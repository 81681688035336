import React from "react";
import { Star, ThumbsUp } from "lucide-react";

const reviews = [
  {
    id: 1,
    rating: 5,
    title: "Fun for big and small 😊",
    date: "August 2024",
    content:
      "Very funny game 😊 Boy at 5 found it was very fun and scary to escape from the Swedes 😊 Recommendeed by us 👍 If this comes to several cities, we will consider trying there too 👍",
    author: "Arthur G",
  },
  {
    id: 2,
    rating: 5,
    title: "100",
    date: "Juni 2024",
    content:
      "A very fun game . For the white family. We got to also see the beautiful old town in Fredrikstad. Nice place. Questions was some easy and some difficult.",
    author: "DayTrip56980001178",
  },
];

const ReviewCard = ({ review }) => (
  <div className="bg-gray-200/50 rounded-lg p-6 shadow-sm">
    {/* Rating */}
    <div className="flex items-center mb-2">
      {[...Array(5)].map((_, index) => (
        <Star
          key={index}
          size={20}
          className={
            index < review.rating
              ? "fill-yellow-400 text-yellow-400"
              : "text-gray-300"
          }
        />
      ))}
    </div>

    {/* Title and Date */}
    <div className="flex justify-between items-center mb-4">
      <h3 className="text-xl font-semibold text-gray-900">{review.title}</h3>
      <span className="text-sm text-gray-500">{review.date}</span>
    </div>

    {/* Content */}
    <p className="text-gray-600 mb-4 whitespace-pre-line">{review.content}</p>

    {/* Author */}
    <div className="flex justify-between items-center">
      <span className="text-gray-700 font-medium">- {review.author}</span>
      <div className="flex items-center text-blue-600">
        <ThumbsUp size={18} className="mr-1" />
        <span className="text-sm">Anbefalt</span>
      </div>
    </div>
  </div>
);

const Anmeldelser = () => {
  return (
    <section className="py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mb-12">
          <h2 className="text-4xl font-bold text-gray-900 mb-2">Anmeldelser</h2>
          <div className="w-24 h-1 bg-black mb-4"></div>
          <p className="text-gray-600 mb-8">
            Disse anmeldelsene er hentet fra{" "}
            <a
              href="https://www.tripadvisor.com.sg/Attraction_Review-g658442-d27966318-Reviews-Escape_City_Game_Gamlebyen_Fredrikstad-Fredrikstad_Fredrikstad_Municipality_Ostf.html"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800 underline"
            >
              TripAdvisor
            </a>
            .
          </p>
        </div>

        {/* Reviews Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {reviews.map((review) => (
            <ReviewCard key={review.id} review={review} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Anmeldelser;
