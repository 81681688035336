import React from "react";
import { Link } from "react-router-dom";
import { ArrowRight } from "lucide-react";

const Hero = ({ setOverlay }) => {
  return (
    <div
      className="relative h-[calc(100vh-100px)] w-full flex items-center justify-center"
      style={{
        backgroundImage: "url(/assets/placeholderhero.png)",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Dark overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      {/* Content */}
      <div className="relative z-10 text-center px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-4">
          Norges første GPS-baserte escape room!
        </h1>

        <p className="text-xl sm:text-2xl text-white mb-8 max-w-3xl mx-auto">
          Prøv EscapeCityGame der man får en escaperoom opplevelse utendørs.
        </p>

        <div className="flex flex-col items-center space-y-4">
          <Link
            to="/purchase"
            className="bg-blue-600 text-white px-8 py-3 rounded-md text-lg font-medium hover:bg-blue-700 transition-colors duration-200"
          >
            Kjøp spill
          </Link>

          <Link
            onClick={() => setOverlay(true)}
            className="text-white flex items-center hover:text-blue-300 transition-colors duration-200"
          >
            <span className="mr-2 underline">Har allerede kode</span>
            {/* <ArrowRight size={20} /> */}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;
