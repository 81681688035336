import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, X, ChevronDown } from "lucide-react";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();

  // Close menu and dropdown when route changes
  useEffect(() => {
    setIsOpen(false);
    setShowDropdown(false);
  }, [location]);

  return (
    <nav className="bg-white shadow-sm fixed w-full z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <Link to="/" className="flex-shrink-0 flex items-center">
              <img
                src="/assets/logopng.png"
                alt="Escape City Game Logo"
                className="h-10 w-auto"
              />
              <span className="ml-2 text-xl font-semibold text-gray-900">
                Escape City Game
              </span>
            </Link>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex md:items-center">
            <div className="relative">
              <button
                onClick={() => setShowDropdown(!showDropdown)}
                className="text-gray-700 hover:text-blue-600 px-3 py-2 text-sm font-medium inline-flex items-center"
              >
                Lokasjoner
                <ChevronDown
                  size={16}
                  className={`ml-1 transition-transform duration-200 ${
                    showDropdown ? "transform rotate-180" : ""
                  }`}
                />
              </button>
              {showDropdown && (
                <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-lg">
                  <Link
                    to="/lokasjoner/gamlebyen-fredrikstad"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Gamlebyen Fredrikstad
                  </Link>
                  <Link
                    to="/lokasjoner/hamar"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Hamar
                  </Link>
                </div>
              )}
            </div>

            <Link
              to="/kontakt"
              className="text-gray-700 hover:text-blue-600 px-3 py-2 text-sm font-medium"
            >
              Kontakt oss
            </Link>
            <Link
              to="/om"
              className="text-gray-700 hover:text-blue-600 px-3 py-2 text-sm font-medium"
            >
              Om oss
            </Link>
            <Link
              to="/purchase"
              className="bg-blue-600 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-blue-700 transition-colors duration-200"
            >
              Kjøp spill
            </Link>
          </div>

          {/* Mobile menu button */}
          <div className="flex items-center md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:text-blue-600 hover:bg-gray-100 focus:outline-none"
            >
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isOpen && (
        <div className="md:hidden">
          <div className="pt-2 pb-3 space-y-1">
            <button
              onClick={() => setShowDropdown(!showDropdown)}
              className="block w-full text-left px-3 py-2 text-base font-medium text-gray-700 hover:text-blue-600 hover:bg-gray-50 flex items-center justify-between"
            >
              Lokasjoner
              <ChevronDown
                size={16}
                className={`transition-transform duration-200 ${
                  showDropdown ? "transform rotate-180" : ""
                }`}
              />
            </button>
            {showDropdown && (
              <div className="pl-4">
                <Link
                  to="/lokasjoner/gamlebyen-fredrikstad"
                  className="block px-3 py-2 text-base font-medium text-gray-700 hover:text-blue-600 hover:bg-gray-50"
                >
                  Gamlebyen Fredrikstad
                </Link>
                <Link
                  to="/lokasjoner/hamar"
                  className="block px-3 py-2 text-base font-medium text-gray-700 hover:text-blue-600 hover:bg-gray-50"
                >
                  Hamar
                </Link>
              </div>
            )}
            <Link
              to="/kontakt"
              className="block px-3 py-2 text-base font-medium text-gray-700 hover:text-blue-600 hover:bg-gray-50"
            >
              Kontakt oss
            </Link>
            <Link
              to="/purchase"
              className="block px-3 py-2 text-base font-medium text-gray-700 hover:text-blue-600 hover:bg-gray-50"
            >
              Kjøp spill
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
