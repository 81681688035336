import React from "react";
import { Link, useParams } from "react-router-dom";
import { MapPin, Users, Route, ArrowRight } from "lucide-react";

const locationData = {
  gamlebyen: {
    title: "Gamlebyen Fredrikstad",
    subtitle: "Opplev historien på en helt ny måte",
    heroImage: "/assets/gamlebyen.png",
    distance: "2.5km",
    type: "Familie, Turister",
    route: "Voldporten → Tollboden",
    description: `Utforsk nordens best bevarte festningsby gjennom et spennende byspill! I Gamlebyen Fredrikstad vil dere lære mer om byens historie og kultur.
Under den svenske okkupasjonen i 1814 ble Kommandant Niels Christian Hals tatt
som krigsfange. Hjelp Kommandanten å flykte fra den svenske okkupasjonen! Dere har 2 timer på
dere før svenskene oppdager dere og burer dere inne bak lås og slå! Hals har klart å snike seg ut og trenger din hjelp til å finne den hemmelige utgangen steg for steg!
Spillet tar deg med på en reise fra Voldporten til Vollgravens skjulte utgang.`,
    usefulInfo: [
      "Byen er dekket med brosteinsgater, og terrenget kan være ujevnt.",
      "Selvom dere spiller på tid, er det tid nok til en kort pause i et av de lokale butikkene.",
      "Spillet foregår utendørs og kan fint spilles med hund.",
      "Spillet kan spilles hele døgnet, men anbefales på dagtid eller kveld.",
      "Vi anbefaler 4 personer per kode, er dere fler så anbefaler vi 2 eller flere koder. Er dere en større gruppe, ta kontakt med oss så fikser vi."
    ],
    locationImage: "/assets/gamlebyen.png",
  },
  hamar: {
    title: "Hamar Sentrum",
    subtitle: "Opplev Innlandets perle",
    heroImage: "/assets/hamar.png",
    distance: "2.1km",
    type: "Familie",
    route: "Hamardomen → Tjuvholmen",
    description: `Bli med på et spennende eventyr langs vakre Mjøsa.

Du har nettopp våknet opp til en ny verden. Det er vanskelig å kjenne seg igjen, og alt rundt deg viser at du har tatt en tidsreise som få er forunt å ta. Det kan virke skummelt, men vi har troa på at du skal klare å komme deg gjennom tidsreisen og ende opp i riktig år. Samtidig vil du bli kjent med Hamars historie, på godt og på vondt.

Året er ikke lenger 2024, men 1567. Hamar er forlatt, det samme er menneskene rundt og nok en krig er nylig ferdig, Klarer du å jobbe deg tilbake til nåtiden før det er for sent?

Perfekt for både lokale og tilreisende som vil oppleve Hamar på en ny måte.`,
    locationImage: "/assets/hamar.png",
    usefulInfo: [
      "Turen går over 2,1km og du avslutter i en annen ende av der du starter.",
      "Stien underveis i turen er flat og passer for alle.",
      "Spillet foregår utendørs, passer perfekt med hund eller barnevogn."
    ]
  },
};

const Location = () => {
  const { id } = useParams(); // Get the location ID from URL
  const location = locationData[id] || locationData.gamlebyen; // Default to Gamlebyen if no ID match

  return (
    <div className="bg-[#FBFBFB]">
      {/* Hero Section */}
      <div
        className="relative h-[calc(100vh-100px)] bg-cover bg-center"
        style={{ backgroundImage: `url(${location.heroImage})` }}
      >
        <div className="absolute inset-0 bg-black/50"></div>
        <div className="absolute inset-0 flex flex-col items-center justify-center text-white">
          <h1 className="text-4xl md:text-6xl font-bold mb-4 text-center">
            {location.title}
          </h1>
          <p className="text-xl md:text-2xl mb-8 text-center">
            {location.subtitle}
          </p>
          <div className="flex flex-col items-center space-y-4">
            <Link
              to="/purchase"
              className="bg-blue-600 text-white px-8 py-3 rounded-md text-lg font-medium hover:bg-blue-700 transition-colors duration-200"
            >
              Kjøp spill
            </Link>

            <Link
              to="/"
              className="text-white flex items-center hover:text-blue-300 transition-colors duration-200"
            >
              <span className="mr-2 underline">Har allerede kode</span>
              <ArrowRight size={20} />
            </Link>
          </div>
        </div>
      </div>

      {/* Location Details Section */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold text-gray-900 mb-2">
            {location.title}
          </h2>
          <div className="w-24 h-1 bg-black mb-8"></div>

          {/* Stats Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
            <div className="flex items-center space-x-3">
              <MapPin className="w-6 h-6 text-blue-600" />
              <div>
                <p className="text-sm text-gray-500">Distanse</p>
                <p className="text-lg font-semibold">{location.distance}</p>
              </div>
            </div>
            <div className="flex items-center space-x-3">
              <Users className="w-6 h-6 text-blue-600" />
              <div>
                <p className="text-sm text-gray-500">Målgruppe</p>
                <p className="text-lg font-semibold">{location.type}</p>
              </div>
            </div>
            <div className="flex items-center space-x-3">
              <Route className="w-6 h-6 text-blue-600" />
              <div>
                <p className="text-sm text-gray-500">Rute</p>
                <p className="text-lg font-semibold">{location.route}</p>
              </div>
            </div>
          </div>

          {/* Description and Image */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center mb-12">
            <div>
              <p className="text-gray-600 text-lg leading-relaxed whitespace-pre-line">
                {location.description}
              </p>
            </div>
            <div>
              <img
                src={location.locationImage}
                alt={`${location.title} detaljer`}
                className="rounded-lg shadow-lg w-full h-auto aspect-square object-cover"
              />
            </div>
          </div>

          {/* Useful Information Section */}
          {location.usefulInfo && (
            <div className="bg-gray-50 rounded-xl p-8">
              <h3 className="text-2xl font-bold text-gray-900 mb-6">Nyttig info</h3>
              <ul className="space-y-4">
                {location.usefulInfo.map((info, index) => (
                  <li key={index} className="flex items-start">
                    <span className="text-blue-600 mr-3">•</span>
                    <span className="text-gray-700">{info}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Location;
