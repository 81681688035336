import React from "react";

const Om = () => {
  return (
    <div className="bg-[#FBFBFB]">
      <section className="py-40">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold text-gray-900 mb-2">Om oss</h2>
          <div className="w-24 h-1 bg-black mb-8"></div>
          <div className="w-full h-8" />
          <p className="mt-4">
            Bak Escape City Game står brødrene Dennis og Pelle Nilsen – to
            eventyrlystne gründere med en lidenskap for utfordringer, spill og
            unike opplevelser. Pelle driver med webdesign og koding av nettsider
            til daglig, og Dennis er kokk og driver med catering og selskaper.{" "}
          </p>
          <p className="mt-4">
            Ideen til Escape City Game kom etter en ferie i Italia sommeren
            2023. Under et besøk i byen Lucca kom vi over et interaktivt byspill
            som kombinerte historie, gåteløsning og eventyr. Fascinert av
            konseptet begynte tankene våre å spinne – kunne dette fungere i
            Norge?
          </p>
          <p className="mt-4">
            Vel hjemme bestemte vi oss for å gjøre ideen til virkelighet.
            Gamlebyen i Fredrikstad, med sine sjarmerende brosteinsgater og rike
            historie, ble den perfekte arenaen for vårt første spill. Med en
            blanding av kreativitet, problemløsning og en solid dose pågangsmot
            skapte vi Escape City Game – Et utendørs spill hvor deltakere må
            samarbeide, løse mysterier og oppdage byen på en ny og spennende
            måte.
          </p>
          <p className="mt-4">
            For oss handler Escape City Game om mer enn bare et spill – det er
            en opplevelse som bringer mennesker sammen, utfordrer tankegangen og
            lar deltakerne se byen med nye øyne.
          </p>
          <p className="mt-4">
            Velkommen til vår verden av gåter, eventyr og oppdagelser!
          </p>
        </div>
      </section>
    </div>
  );
};

export default Om;
